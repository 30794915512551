@import './../../../../../assets/sass/custom.scss';

.file-upload {
  margin-bottom: $margin-medium-vertical;
}

@include media-breakpoint-down(sm) {
  .file-upload {
    margin-top: $margin-medium-vertical;
  }
}
