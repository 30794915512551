@import './../../assets/sass/custom.scss';

#app-nav-bar {
  background: $darkBlue;

  .navbar-nav > .nav-link,
  .navbar-nav > .dropdown > .nav-link {
    color: $white;
  }

  .navbar-nav > .nav-link.active,
  .navbar-nav > .nav-link:hover {
    color: $white;
  }
}
