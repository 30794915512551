@import '~react-dropzone-uploader/dist/styles.css';
@import './../../../../../../assets/sass/custom.scss';

.select-file-dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 250px;
  min-height: auto;
  overflow: auto;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  border: 4px dashed $blue;
  border-radius: $border-radius;
  background: $white;

  &.active {
    border-style: solid;
    border-color: $darkBlue;

    label.select-file-label {
      color: $darkBlue;
    }

    span.btn {
      background: $darkBlue;
      border-color: $darkBlue;
    }
  }

  &.reject {
    border: 2px dashed $danger;
  }

  &.accepted {
    border: 2px solid $gray;
    height: 100px;
  }

  > label.select-file-label {
    line-height: 2em;
    font-weight: normal !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    margin: 0;
    color: $blue;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    text-align: center;

    span.btn {
      cursor: pointer;
      font-weight: normal !important;
      margin-top: 0.5em;

      .folder-icon {
        margin-right: $margin-base-horizontal;
      }
    }
  }

  > div.select-file-preview {
    margin: auto;
    padding: 0 $padding-medium-horizontal;
    border: 0;

    // cannot use own classname => reset css
    > span.dzu-previewFileName {
      font-family: inherit;
      font-size: $font-size-base;
      font-weight: inherit;
      color: inherit;
    }
  }
}
