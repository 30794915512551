@import './../../../../../assets/sass/custom.scss';
@import '~leaflet/dist/leaflet.css';

.map {
  .leaflet-container {
    margin: 0;
    height: 600px;
  }

  p.small {
    margin-top: $margin-base-vertical;
  }
}
