@import './../../../assets/sass/custom.scss';

#data {
  .header-info {
    display: block;
    text-align: right;
    margin-bottom: $margin-medium-vertical;
    font-size: $font-size-sm;
    color: $secondary;
    line-height: 1.5;
  }

  .table-responsive {
    max-height: 425px;
  }
}
