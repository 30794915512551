@import-normalize; /* bring in normalize.css styles */
@import 'assets/sass/custom.scss';
@import 'assets/sass/container.scss';
@import '~bootstrap/scss/bootstrap';

body {
  margin: 0;
  padding: 0;
  font-family: verdana, sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  color: $dark;
  margin-bottom: $margin-medium-vertical;
}

h3 {
  color: $dark;
  font-size: $font-size-lg;
  border-bottom: 1px solid $gray;
  padding-bottom: $padding-base-vertical;
  margin-top: $margin-large-vertical;
}

h4 {
  font-size: $font-size-base;
  font-weight: bold;
  margin-bottom: $margin-base-vertical;
}

h1 ~ p.sub-header,
h2 ~ p.sub-header,
h3 ~ p.sub-header,
h4 ~ p.sub-header {
  color: $secondary;
  text-align: center;
  margin-top: -$margin-base-vertical;
  margin-bottom: $margin-large-vertical;
}

p {
  margin-bottom: $margin-medium-vertical;
}

pre {
  background: $white;
  padding: $padding-medium-vertical $padding-medium-horizontal;
  margin-bottom: 0;
}

.margin-bottom {
  margin-bottom: $margin-large-vertical !important;
}

.container {
  padding: 0 $padding-base-horizontal;
}

input.form-control, input.form-check-input {
  background-color: $white;
  border-color: $gray;
}

nav.navbar {
  padding: $padding-base-vertical 0;

  .navbar-brand,
  .navbar-brand:hover {
    padding: 0;
    font-weight: bold;
  }

  .navbar-nav > .nav-link,
  .navbar-nav > .dropdown > .nav-link {
    padding: $padding-base-vertical $padding-large-horizontal;
    font-size: $font-size-base;

    &.first {
      padding-left: 0 !important;
    }
  }

  .navbar-nav > .nav-link.active {
    font-weight: bold;
    text-decoration: underline;
  }

  .navbar-nav > .nav-link:hover {
    text-decoration: underline;
  }

  @include media-breakpoint-down(lg) {
    &.navbar-expand-lg {
      .container {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .navbar-nav > .nav-link {
      padding-right: $padding-large-horizontal;
      padding-left: $padding-large-horizontal;
    }
  }
}
