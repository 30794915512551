@import './../../../../../assets/sass/custom.scss';

.stepper {
  .steps {
    position: relative;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    .stepper-step {
      position: relative;
      display: table-cell;
      text-align: center;
      padding: 0.5rem;
    }

    .stepper-step:after {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 1.75rem;
      width: 100%;
      height: 0.125rem;
      background-color: $gray;
      z-index: 1;
    }

    .stepper-indicator {
      position: relative;
      display: block;
      z-index: 2;
    }

    .stepper-label {
      position: relative;
      display: block;
      margin: 0.5rem 0;
      color: $gray;
      z-index: 2;
    }

    .stepper-info {
      position: relative;
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      border: 1px solid $gray;
      border-radius: 50%;
      background-color: $gray;
      font-size: 1.25rem;
      line-height: 2.5rem;
      text-align: center;
      color: $white;
      z-index: 2;
    }
  }

  .step-panel {
    position: relative;
  }
}

@include media-breakpoint-down(sm) {
  .stepper {
    .steps {
      .stepper-step {
        display: block;
        text-align: left;
      }

      .stepper-step:after {
        content: ' ';
        position: absolute;
        left: 1.75rem;
        top: 2.5rem;
        bottom: -0.5rem;
        width: 0;
        height: auto;
        border-left: 0.125rem solid $gray;
      }

      .stepper-label {
        padding-left: 1rem;
      }

      .stepper-indicator,
      .stepper-label {
        display: table-cell;
      }
    }

    .stepper-panel {
      margin-left: 3.5rem;
    }
  }
}

.stepper .stepper-step:last-child:after {
  display: none;
}

.stepper .stepper-step.is-complete .stepper-info {
  border-color: $primary;
  background-color: $primary;
}

.stepper .stepper-step.is-complete .stepper-label {
  color: $primary;
}

.stepper .stepper-step.is-active .stepper-info {
  border-color: $primary;
  background-color: $primary;
}

.stepper .stepper-step.is-active .stepper-label {
  color: $primary;
}

.stepper .stepper-step.is-error .stepper-info {
  border-color: $danger;
  background-color: $danger;
}

.stepper .stepper-step.is-error .stepper-label {
  color: $danger;
}
