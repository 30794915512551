@import './../../assets/sass/custom.scss';

.app-header {
  background: $blue;

  .container {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a.aims-logo {
      flex-grow: 0;
      img {
        width: 300px;
      }
    }

    .title {
      font-size: 2.2rem;
      font-weight: 500;
      color: $white;
      line-height: 2.5rem;

      img.aims-logo-sml {
        margin-right: 8px;
        height: 2.5rem;
        vertical-align: bottom;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .container {
      height: 80px;

      a.aims-logo {
        display: none;
      }
    }
  }
}
