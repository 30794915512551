@import './../../../../assets/sass/custom.scss';

.step-select-variables {
  .card-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;

    button {
      .form-check-inline {
        margin-right: 0.6rem;

        input {
          cursor: pointer;

          &.form-check-input:checked {
            background-color: $light;
            border-color: $light;
          }
        }
      }
    }

    .card-title {
      margin-bottom: 0;
      line-height: 1.5rem;
      padding: $padding-base-vertical 0;
    }
  }
}
