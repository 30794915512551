@import './../../assets/sass/custom.scss';

main {
  > .container {
    padding-top: $padding-large-vertical;
    padding-bottom: $padding-large-vertical;

    .container {
      &.form {
        
        .form-group {
          margin-bottom: $margin-medium-vertical;
        }
        
        label {
          font-weight: bold;
        }
        // checkbox and radio button labels
        input ~ label {
          font-weight: normal;
        }
        
        textarea {
          background-color: $white;
        }

        .form-check {
          margin: 0 $margin-medium-horizontal $margin-base-vertical;
        }
        
        small.form-text {
          display: block;
          margin-top: 0.25rem;
          font-size: $font-size-sm;
          line-height: 1.5;
        }
      }

      .text-info {
        color: $secondary !important;
        line-height: 1.5;

        margin-bottom: $margin-medium-vertical;

        .info-icon {
          margin-right: $margin-base-horizontal;
          color: $primary;
        }
      }

      h3:first-child {
        margin-top: 0;
      }

      div.licence {
        text-align: center;
        border: 1px solid $gray;
        border-radius: $border-radius;
        padding: $padding-base-vertical $padding-base-horizontal;
        background: $white;
      }
    }
  }
}
