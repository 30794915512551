@import './../../assets/sass/custom.scss';

#extraction-tool-nav-bar {
  .navbar-brand {
    color: $dark;
  }

  .navbar-nav > .nav-link,
  .navbar-nav > .dropdown > .nav-link {
    color: $dark;
  }
  .navbar-nav > .nav-link.active,
  .navbar-nav > .nav-link:hover {
    color: $dark;
  }
}
