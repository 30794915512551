// Override default variables before the import
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';

/* --- begin customisation ---- */

// colours
$lightGray: #f8f8fa;
$gray: #d9d9d9;
$blue: #335b84;
$darkBlue: #2c4e72;
$colors: (
        'gray': $gray,
        'blue': $blue,
        'darkBlue': $darkBlue
);

// theme colors
$primary: $blue;
$dark: #25425f;
$light: #708ca8;
$success: #0c8120;

$body-bg: $lightGray;

// common
$border-radius: 4px !default;

$padding-base-vertical: 0.5rem !default;
$padding-base-horizontal: 0.5rem !default;
$padding-medium-vertical: 0.75rem !default;
$padding-medium-horizontal: 1.25rem !default;
$padding-large-vertical: 2rem !default;
$padding-large-horizontal: 2rem !default;
$padding-xl-vertical: 3.5rem !default;
$padding-xl-horizontal: 3.5rem !default;

$margin-small-vertical: 0.3rem !default;
$margin-small-horizontal: 0.3rem !default;
$margin-base-vertical: 0.5rem !default;
$margin-base-horizontal: 0.5rem !default;
$margin-medium-vertical: 1rem !default;
$margin-medium-horizontal: 1rem !default;
$margin-large-vertical: 2.5rem !default;
$margin-large-horizontal: 2.5rem !default;

$font-size-sm: 0.8rem;
$font-size-xs: 0.7rem;

/* --- end customisation ------ */

@import '~bootstrap/scss/variables';
