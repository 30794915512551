@import './../../../../../assets/sass/custom.scss';

.table-responsive {
  overflow-y: auto;
  overflow-x: hidden;

  .table {
    margin-bottom: 0;
    background: $white;
  }
}
