@import './../../assets/sass/custom.scss';

.app-footer {
  background: $dark;
  padding: $padding-base-vertical 0;

  > .container > ul.nav {
    & > li > .nav-link {
      font-size: $font-size-sm;
      color: $white;
    }
    & > li > .nav-link.active,
    & > li > .nav-link:hover {
      color: $white;
      text-decoration: underline;
    }

    &:first-child > li > a {
      padding-left: 0;
    }
  }
}
