@import './custom';

.container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1440px;

  padding-left: 15px;
  padding-right: 15px;
}

@include media-breakpoint-up(lg) {
  .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
