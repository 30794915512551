@import './../../../../assets/sass/custom.scss';

.step-submit {
  div.licence {
    margin-left: 2rem;
  }

  span.character-counter {
    float: right;
    padding: 0 $padding-base-horizontal;
    color: $blue;
  }

  .table-responsive {
    max-height: 425px;
  }
}
