@import './../../../../../../assets/sass/custom.scss';

.info-text {
  margin: $margin-large-vertical 0;
  padding: $padding-base-vertical $padding-base-horizontal;

  border: 0;
  background: $gray-100;

  svg {
    margin-right: 0.5rem;
  }

  label {
    margin: $margin-base-vertical 0;
  }
}
