@import './../../../assets/sass/custom.scss';

#home {
  max-width: 100%;
  padding: 0 !important;

  .container {
    padding-top: $padding-xl-vertical;
    padding-bottom: 0;
  }

  img,
  video {
    width: 100%;
  }

  #hero {
    position: relative;
    width: 100%;
    padding: 7% 0;
    overflow: hidden;

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('./../../../assets/images/eReefs-header.png') no-repeat
        center;
      background-size: cover;
      z-index: 1;
    }

    video {
      position: absolute;
      bottom: 50%;
      height: auto;
      min-height: 100%;
      min-width: 100%;
      right: 50%;
      width: auto;
      z-index: 2;
      transform: translateX(50%) translateY(50%);
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $dark;
      opacity: 0.8;
      z-index: 3;
    }

    .container {
      z-index: 4;
      position: relative;
      margin: 0 auto;
      padding-top: 0;

      h1 {
        font-weight: bold;
        font-size: 3em;
        line-height: 1em;
        text-align: center;
        color: $white;
      }

      p.sub-header {
        color: $light;
        font-size: 2.2em;
      }

      p.button {
        margin: (2 * $margin-large-vertical) 0 0;
        a {
          padding: (2 * $padding-base-vertical) (3 * $padding-large-vertical);
        }
      }

      @include media-breakpoint-down(lg) {
        h1 {
          font-size: 2.6em;
        }

        p.sub-header {
          font-size: 1.6em;
        }

        p.button {
          margin: $margin-large-vertical 0 0;

          button {
            padding: $padding-base-vertical (2 * $padding-large-vertical);
          }
        }
      }

      @include media-breakpoint-down(md) {
        h1 {
          font-size: 2.4em;
        }

        p.sub-header {
          font-size: 1.4em;
        }
      }

      @include media-breakpoint-down(sm) {
        h1 {
          font-size: 2em;
        }

        p.sub-header {
          font-size: 1.3em;
        }
      }
    }
  }

  #eReefs-description {
    figure {
      margin-bottom: 1rem;
    }

    @include media-breakpoint-up(xl) {
      figure {
        margin-bottom: 0;
        padding-right: $padding-large-horizontal;
      }
    }
  }

  #model-definitions {
    img {
      margin-bottom: 1rem;
    }

    @include media-breakpoint-up(lg) {
      img {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(xl) {
      img {
        padding-left: $padding-large-horizontal;
      }
    }
  }

  #collaborators {
    background: $darkBlue;
    margin-top: $padding-xl-vertical;

    .container {
      padding-top: $padding-large-vertical;
      padding-bottom: $padding-large-vertical;

      .row {
        h2 {
          color: $white;
          margin-bottom: 0;
        }

        &.collaborator-logos {
          margin-top: $margin-medium-vertical;
          margin-bottom: $margin-medium-vertical;

          .col {
            margin-top: $margin-base-vertical;
            margin-bottom: $margin-base-vertical;
            flex-grow: 0;
            text-align: center;

            img {
              max-height: 70px;
              height: auto;
              width: auto;
            }
          }
        }

        p {
          color: $white;
          margin-bottom: 0;
        }
      }
    }
  }

  #other-data {
    background: $gray-200;

    .container {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      @include media-breakpoint-up(md) {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
      }

      .box {
        background-color: #fff;
        box-shadow: 0 40px 70px 0 rgba(17, 58, 95, 0.15);
        margin-left: 0;
        margin-right: 0;
        padding: $padding-large-vertical $padding-base-horizontal;
      }
    }
  }
}
