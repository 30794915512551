@import './../../../../assets/sass/custom';

.step-nav {
  background: $white;
  width: 100%;
  border: 1px solid $gray;
  border-radius: $border-radius;
  padding: $padding-base-horizontal;

  .step-nav-item {
    height: 100%;

    > h5 {
      font-weight: bold;
      margin-top: 0;
      margin-bottom: $margin-base-vertical;
    }
    > p {
      margin-bottom: 0;
      font-size: $font-size-sm;
    }
  }

  .nav-link.active {
    background: $dark;
  }

  .nav-link:hover:not(.active) {
    color: $blue;
    background: $gray-200;
  }
}

@include media-breakpoint-down(sm) {
  .step-nav {
    flex-direction: column !important;

    .step-nav-item {
      height: auto;

      > h5 {
        font-size: $font-size-base;
      }

      > p {
        display: none;
      }
    }
  }
}
