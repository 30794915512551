@import './../../../../assets/sass/custom.scss';

.accordion {
  padding-bottom: $padding-base-vertical;
  background: $white;

  .accordion-item {
    padding: $padding-base-vertical $padding-base-horizontal;
    background: $white;
    border: 0;
    
    .accordion-header .accordion-button {
      background: none;
      cursor: pointer;
      color: $link-color;
      display: flex;
      border-bottom: 1px solid $gray;
      
      &:focus {
        border: 0;
      }

      &::after {
        display: none;
      }

      span {
        flex-grow: 1;
        padding-right: $padding-base-horizontal;
      }

      &.collapsed .rotate-icon {
        transform: rotate(180deg);
      }
    }

    .accordion-body {
      padding-bottom: 0;
    }
  }
}
