@import './../../../../../assets/sass/custom.scss';
@import '~react-datepicker/dist/react-datepicker.css';

.date-range {
  
  .date-picker-input {
    display: inline-block;
    margin-bottom: $margin-base-vertical;

    // adjustments for IE 11
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      &.form-control {
        display: inline-block;
      }
    }

    .react-datepicker {
      background-color: $white;
      border-color: $gray;
      font-size: 1rem;
      font-family: inherit;

      .react-datepicker__navigation {
        top: 13px;
      }

      .react-datepicker__header {
        background-color: $white;
        border-color: $gray;
        padding: $padding-medium-vertical 0;

        .react-datepicker__current-month {
          margin-bottom: $margin-base-vertical;
        }

        .react-datepicker__day-names, .react-datepicker__header__dropdown {
          margin-top: $margin-small-vertical;
        }
      }

      .react-datepicker__day, .react-datepicker__day-name {
        margin: $margin-small-vertical $margin-small-horizontal;
      }
      
      .react-datepicker__day--in-selecting-range {
        background-color: $light;
        color: $white;
      }
      
      .react-datepicker__day--in-range.react-datepicker__day--in-selecting-range {
        background-color: $primary;
        color: $white;
      }

      .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range){
        background-color: $gray;
      }
    }
  }
}
